body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label {font-weight: bold;}
.bold {font-weight: bold;}
.card {margin-bottom: 10px;}
.view {min-height: 100vh;}
.content {margin-top: 20px;}

.top-nav {height: 43px; background-color: gold;}
.top-nav-font {font-size: 0.9em; font-weight: 500;}
.top-nav-font .nav-link {color: black;}
.top-nav .nav-link:hover {color: black; text-decoration: underline;}
.top-nav-weather {margin-top: 10px;}
.top-navbar {margin-top: -6px;}
.top-nav-gcs {margin-top: 5px;}

.green-bar {
  background-color: #006e01 !important;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dffffff', endColorstr='#00ffffff', GradientType=0)
}
.masthead .nav-link {color: white !important;}
.masthead .nav-link:hover {color: yellow !important; text-decoration: underline;}
.masthead img {height: 150px; margin: -18px 0;}
.masthead .school-name {color: white; font-style: italic; font-size: 3.5em; margin-top: 5px; font-family: Cambria, Arial, serif; cursor: pointer;}
.masthead .school-name-small {font-size: 2em; cursor: pointer;}
ul.masthead-nav {margin-bottom: -7px; font-size: 14.4px;}

.footer {color: white; padding-top: 15px; margin-top: 15px;}
.footer h6 {line-height: 0.9em; font-size: 0.85em;}
.footer a {color: yellow;}
.footer a:hover {color: white; text-decoration: underline;}
.footer .right-separator {margin-right: 10px;}
.footer .top-separator {margin-top: 5px;}

img.bulletin-icon {height: 20px; padding-right: 10px; margin-bottom: 4px;}

.card-header {font-size: 1.25em; font-weight: bold;}
.card-title {font-size: 1.15em;}
.card-subtitle {font-size: 1.10em;}
.card-footer {font-size: 0.85em; font-style: italic;}

.bulletin-date {font-weight: bold; font-size: 1.2em; margin: 20px 0;}
.bulletin-item {margin-bottom: 30px;}
.bulletin-item p {display: inline;}
.bulletin-tease {font-weight: bold;}
.bulletin-contact {font-size: 0.85em; font-style: italic; float: right;}
.bulletin-web-nav-button {color: blue; cursor: pointer; padding: 0 5px;}

.short-calendar-date {width: 25%;}
.short-calendar-type {width: 25%;}
.short-calendar-description {width: 50%;}
.short-calendar p {margin: 0; font-size: 0.9em;}
.short-calendar-today {background-color: #F2F5A9 !important;}
.short-calendar-item td {border-top: 1px solid #aaa !important;}

.calendar tbody tr td {width: 14%; height: 125px; border: 1px solid #DEE2E6;}
.calendar-controls {padding-bottom: 20px;}
.calendar-controls span {font-size: 1.5em; font-weight: bold;}
.calendar-controls .btn {margin-top: 3px; font-weight: bold;}
.calendar-date {font-weight: bold; font-style: italic;}
.calendar-item {font-size: 0.85em; margin-bottom: 5px; padding: 0 5px 3px 5px; border: 1px solid #555; border-radius: 5px;}

.phame {background-color: rgb(238,206,205);}
.academics {background-color: rgb(220,233,213);}
.events {background-color: rgb(253,242,208);}
.after.school {background-color: rgb(211,226,242);}
.spare-color {background-color: rgb(230,210,220);} /* pink */

.contact {min-height: 500px;}
.contact .row {border-bottom: 1px solid #eee; padding: 3px 3px;}
.contact .card-body {font-size: 0.95em;}
.contact-bold {font-weight: bold;}
.contact-name-list {border-bottom: 1px solid #ccc;}

.staff-directory table tbody tr td {cursor: pointer;}
.staff-directory img {width: 130px;}
.staff-directory-list {}
.staff-directory-list-scroll {max-height: 500px; overflow-y: scroll;}
.staff-directory-schedule {text-indent: 15px;}
.staff-directory-entry p {line-height: 1rem;}
.staff-directory .card-header {padding-top: 20px; padding-bottom: 5px;}

.carousel-picture {text-align: center;}
.carousel-picture img {height: 40vh;}
.carousel {background-color: #999;}
.carousel-control-prev-icon {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e") !important;}
.carousel-control-next-icon {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e") !important;}

.quick-links ul {padding-left:5px;}

.page-item .card-title {font-weight:bold;}
.page-item .card-subtitle {font-style: italic;}
.page-item .card-text {border-top: 1px solid #ccc; margin-top: 5px; padding-top: 5px;}
.card-text-brief {max-height: 200px; overflow-y: hidden;}
.card-text-show-more {cursor: pointer; color: blue; font-style: italic; font-size: 0.95em;}
.item-link {font-size: 0.90em;}
.item-link-indent {line-height: 2em;}
.item-link-divider {margin:0; font-weight: bold;}
.item-link-wrapper {margin-left:-5px; line-height: 1.25em;}

.error-404 {height: 400px; margin-top: 50px;}

.fader {height: 75px; position: absolute; bottom: 50px; width: 100%; margin: 0; background-image: linear-gradient(to bottom, transparent, white);}

